.left-side-inide {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h5 {
    display: none;
  }
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    display: flex;
    align-items: center;

    color: #ffffff;
  }
  h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 55px;
    line-height: 67px;
    display: flex;
    align-items: center;

    color: #ffffff;
  }
  .lable-button {
    display: flex;
    align-items: center;
    margin-top: 3rem;
    .businner {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 19px;
      line-height: 42px;
      margin-left: 13px;
      /* or 221% */

      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;

      border: 1.5px solid #ffffff;
      border-radius: 15px;
      background-color: transparent;
      width: 245.95px;
      height: 63.09px;
      color: #ffffff;
      &:hover {
        top: -5px;
        position: relative;
      }
    }
    .user {
      width: 205.95px;
      height: 63.09px;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 15px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 19px;
      line-height: 42px;
      /* or 221% */

      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      color: #283790;
      &:hover {
        top: -5px;
        position: relative;
      }
    }
  }
}
.rightside-cards {
  display: flex;
  width: 50%;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  padding-top: 1%;
  padding-bottom: 1%;
  h4 {
    display: none;
  }
  .cardsinside {
    img {
      width: 70%;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48%;
    height: 29%;

    background: #ffffff;
    border: 0.5px solid #e5e5e5;
    border-radius: 25px;
    &:hover {
      top: -5px;
      position: relative;
    }
  }
}

.homepaage {
  background: linear-gradient(180deg, #283790 0%, rgba(40, 55, 144, 0) 174.12%);
  height: calc(100vh - 109px);
}

.alice-carousel__dots {
  display: none !important;
}
.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  padding: 0px !important;
  display: block !important;
  width: 0px !important;
}
.custom-carsolue {
  display: none;
  margin-top: 108px;

  .rightsideimge {
    position: absolute;
    top: 39%;
    right: 5%;
    transform: translateY(-42%);
  }
  .leftsideimag {
    position: absolute;
    top: 39%;
    left: 5%;
    transform: translateY(-42%);
  }
  h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 43px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #5f6163;
    padding-top: 1rem;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 42px;
    /* or 280% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #5f6163;
    margin: auto;
    margin-bottom: 1rem;

    width: 90%;
  }
  .sub {
    background-color: #ebebeb;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;

    .imgsectiom {
      background-color: white;
      border-radius: 15px;
      width: 80%;
      display: flex;
      justify-content: center;
      position: relative;
      margin-top: 2.5rem;
      img {
        height: 369px;

        padding-top: 23px;
      }
    }
    .textdata {
      width: 100%;
      background: white;
      margin-top: 10%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 9px;
      padding-bottom: 5px;
      h3 {
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        display: flex;
        align-items: center;
        text-align: center;

        color: #5f6163;
      }
      h5 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;

        color: #5f6163;
      }
    }
  }
}

.homepaage {
  display: flex;
  margin-top: 108px;
  // height: calc(42rem - 108px);
  padding-left: 4.2%;
  padding-right: 4.2%;

  justify-content: space-between;
  .lefts {
    width: 30%;
    display: flex;

    align-items: flex-end;
    // border-bottom: 1px solid #EBEBEB;;
    img {
      width: 423px;
      height: 423px;
    }
  }
  .left {
    // width: 40%;
    // display: flex;
    // align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: end;
    /* align-items: flex-end; */
    bottom: 0;
    // border-bottom: 1px solid #EBEBEB;;
    img {
      // width: 554px;
      // height:639px;
      height: 64vh;
    }
  }
  .right {
    // width: 60%;
    display: flex;
    align-items: center;
    // border-bottom: 1px solid #EBEBEB;;
    .sub {
      .mar {
        margin-top: 2rem;
        h6 {
          display: none;
        }
        h5 {
          font-family: "Montserrat";

          font-weight: 700 !important;
          font-size: 28px;
          line-height: 34px;
          display: flex;
          align-items: center;
          text-align: center;
        }
      }
      h1 {
        font-style: normal;
        font-weight: 800;
        font-size: 55px;
        line-height: 63px;
        display: flex;
        align-items: center;
        margin: 0;
        color: #283790;
      }
      p {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 42px;
        /* or 210% */
        padding-right: 7%;
        margin: 0;
        display: flex;
        align-items: center;

        color: #5f6163;
      }
      .sectionlabel {
        display: flex;
        align-items: center;
      }
      .cliselabel2 {
        display: none !important;
      }
      .label1 {
        background: #ffffff;
        border: 0.5px solid #ebebeb;
        border-radius: 15px;
        font-style: normal;
        font-weight: 700;
        font-size: 17px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
        height: 55px;
        width: 200px;
        color: #18191d;
        margin-bottom: 0;
        margin-right: 10px;
        margin-top: 3rem;
        justify-content: center;
        z-index: 20;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        transition: 1s;
        &:hover {
          font-size: 17px;
          background: #fff;
        }
        &:after {
          background: #283790;
          content: "";
          height: 155px;
          left: -75px;
          opacity: 1;
          position: absolute;
          top: -50px;
          width: 50px;
          -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          -webkit-transform: rotate(35deg);
          -ms-transform: rotate(35deg);
          transform: rotate(35deg);
          z-index: -10;
        }

        &:hover:after {
          left: 120%;
          -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        }

        &:active {
        }

        &:focus {
        }
      }
      .label2 {
        background: #283790;
        border: 0.5px solid #283790;
        border-radius: 15px;
        margin-bottom: 0;
        font-style: normal;
        font-weight: 800;
        font-size: 17px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
        margin-top: 3rem;
        color: #ffffff;
        height: 55px;
        width: 250px;
        justify-content: center;
        z-index: 20;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        transition: 1s;
        &:hover {
          font-size: 17px;
          background: #283790;
          border: 0.5px solid #283790;
        }
        &:after {
          background: #fff;
          content: "";
          height: 155px;
          left: -75px;
          opacity: 1;
          position: absolute;
          top: -50px;
          width: 50px;
          -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          -webkit-transform: rotate(35deg);
          -ms-transform: rotate(35deg);
          transform: rotate(35deg);
          z-index: -10;
        }

        &:hover:after {
          left: 120%;
          -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        }

        &:active {
          background: #4caf50;
          border: 0.5px solid #4caf50;
        }

        &:focus {
          background: #4caf50;
          border: 0.5px solid #4caf50;
          box-shadow: 0 0 0 0 #4caf50;
        }
      }
    }
  }
}
.join {
  font-weight: bold;
  font-size: 26px;
}
.MarketText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  text-align: center;
  letter-spacing: 0.002em;
  margin-top: 150px;
}
.Market {
  div {
    margin-top: 59px;
  }
  p {
    width: 980px;

    margin: auto;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: lighter;
    font-size: 20px;
    line-height: 45px;
    /* or 225% */

    display: flex;
    align-items: center;
    text-align: center;
  }
}

// .custompage{
//     display: flex;
//     /* margin-top: 108px; */
//     height: calc(46rem - 108px);
//     padding-left: 7%;
//     padding-right: 8.9%;
//     .lefts
//     {
//       width: 40%;
//       display: flex;
//       align-items: center;
//       img{
//         width: 423px;
//         height: 423px;
//       }
//     }
//     .leftsc
//     {
//       width: 40%;
//       display: flex;
//       align-items: center;
//       justify-content: end;
//       img{
//         width: 423px;
//         height: 423px;
//       }
//     }
//     .right{
//       width: 60%;
//       display: flex;
//       align-items: center;
//   justify-content: end;
//       .sub{
//         width: 86%;
//         .mar{
//           margin-top: 2rem;
//           h6{
//             display: none;
//           }

//         }
//           h1{
//               font-style: normal;
// font-weight: 800;
// font-size: 52px;
// line-height: 63px;
// display: flex;
// align-items: center;
// margin: 0;
// color: #5F6163;
//           }
//           p{
//               font-family: 'Montserrat';
// font-style: normal;
// font-weight: 400;
// font-size: 20px;
// line-height: 42px;
// /* or 210% */

// margin: 0;
// display: flex;
// align-items: center;

// color: #5F6163;
//           }
//           .sectionlabel{
//               display: flex;
//               align-items: center;

//           }
//           .cliselabel2{
//             display: none !important;
//           }
//           .label1{
//               background: #FFFFFF;
//               border: 0.5px solid #EBEBEB;
//               border-radius: 15px;
// font-style: normal;
// font-weight: 700;
// font-size: 17px;
// line-height: 21px;
// display: flex;
// align-items: center;
// text-align: center;
// height: 55px;
// width: 200px;
// color: #5F6163;
// margin-bottom: 0;
// margin-right: 10px;
// margin-top: 3rem;
// justify-content: center;
// z-index: 20;
// overflow: hidden;
// position: relative;
// cursor: pointer;
// transition: 1s;
// &:hover {
//   font-size: 17px;
//   background: #fff;
// }
// &:after {

//             background: #4CAF50;
//   content: "";
//   height: 155px;
//   left: -75px;
//   opacity: 1;
//   position: absolute;
//   top: -50px;
//   width: 50px;
//   -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//   transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//   -webkit-transform: rotate(35deg);
//   -ms-transform: rotate(35deg);
//   transform: rotate(35deg);
//   z-index: -10;
// }

// &:hover:after {
//   left: 120%;
//   -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//   transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
// }

// &:active {

// }

// &:focus {

// }
//           }
//           .label2{
//               background: #4CAF50;
//               border: 0.5px solid #4CAF50;
//            border-radius: 15px;
// margin-bottom: 0;
// font-style: normal;
// font-weight: 800;
// font-size: 17px;
// line-height: 21px;
// display: flex;
// align-items: center;
// text-align: center;
// margin-top: 3rem;
// color: #FFFFFF;
// height: 55px;
// width: 250px;
// justify-content: center;
// z-index: 20;
// overflow: hidden;
// position: relative;
// cursor: pointer;
// transition: 1s;
// &:hover {
//   font-size: 17px;
//             background: #4CAF50;
//   border: 0.5px solid #4CAF50;
// }
// &:after {
//   background: #fff;
//   content: "";
//   height: 155px;
//   left: -75px;
//   opacity: 1;
//   position: absolute;
//   top: -50px;
//   width: 50px;
//   -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//   transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//   -webkit-transform: rotate(35deg);
//   -ms-transform: rotate(35deg);
//   transform: rotate(35deg);
//   z-index: -10;
// }

// &:hover:after {
//   left: 120%;
//   -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//   transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
// }

// &:active {
//             background: #4CAF50;
//   border: 0.5px solid #4CAF50;
// }

// &:focus {
//             background: #4CAF50;
//   border: 0.5px solid #4CAF50;
//   box-shadow: 0 0 0 0 #4CAF50;
// }
//           }
//       }
//   }
// }
.alabout {
  padding-left: 7%;
  padding-right: 8.9%;
  padding-top: 2rem;
  padding-bottom: 1.4rem;
  display: block;
  h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 73px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #5f6163;
  }
  p {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 42px;
    /* or 233% */

    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;

    color: #5f6163;
  }
  .content {
    display: flex;
    align-items: center;
    gap: 3.8%;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 7%;
    padding-right: 8.5%;
    justify-content: space-between;
    margin-bottom: 4rem;
    .sub {
      width: 30%;
      margin-top: 4rem;

      .imgsectiom {
        width: auto;
        height: 392px;
        display: flex;
        border-radius: 45px;
        align-items: flex-end;
        justify-content: center;
        background: #ffffff;
        border: 0.5px solid #e5e5e5;
        z-index: 20;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        transition: 1s;
        &:hover {
        }
        &:after {
          background: black;
          content: "";
          height: 492px;
          left: -190px;
          opacity: 1;
          position: absolute;
          top: -50px;
          width: 50px;
          -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          -webkit-transform: rotate(35deg);
          -ms-transform: rotate(35deg);
          transform: rotate(35deg);
          z-index: 10;
        }

        &:hover:after {
          left: 140%;
          -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
        }

        &:active {
          background: #4caf50;
          border: 0.5px solid #4caf50;
        }

        &:focus {
          background: #4caf50;
          border: 0.5px solid #4caf50;
          box-shadow: 0 0 0 0 #4caf50;
        }
        img {
          width: auto;
          height: 365px;
        }
      }
      h3 {
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
      }
      h5 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin-bottom: 0.8rem;
        color: #5f6163;
      }
    }
  }
}

.Seconcards {
  margin: 109px 90px;
  display: flex;
  justify-content: space-between;
}
.Seconcards > div:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}
.Viralcards {
  width: 100%;
  padding-top: 4%;
  padding-left: 4.2%;
  padding-right: 4.2%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 700px) {
  .alabout {
    display: none;
  }
  .future_heding {
    width: 70%;
    margin: auto;
  }
  .future_heding > h1 {
    font-size: 40px;
  }

  .join {
    display: none;
  }
  .homepaage {
    display: block;
    margin-top: 108px;
    height: calc(42rem - 108px);
    padding-left: 7%;
    padding-right: 7%;

    .lefts {
      width: 40%;
      display: flex;

      align-items: flex-end;
      border-bottom: 1px solid #ebebeb;
      img {
        width: 423px;
        height: 423px;
      }
    }
    .left {
      width: 40%;
      display: flex;
      align-items: flex-end;
      border-bottom: 1px solid #ebebeb;
      img {
        width: 416px;
        height: 504px;
      }
    }
    .right {
      width: 60%;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ebebeb;
      .sub {
        h6 {
          display: none;
        }
        .mar {
          margin-top: 2rem;
        }
        h1 {
          font-style: normal;
          font-weight: 800;
          font-size: 52px;
          line-height: 63px;
          display: flex;
          align-items: center;
          margin: 0;
          color: #5f6163;
        }
        p {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 42px;
          /* or 210% */
          padding-right: 7%;
          margin: 0;
          display: flex;
          align-items: center;

          color: #5f6163;
        }
      }
    }
  }

  .custompage {
    display: flex;
    /* margin-top: 108px; */
    height: calc(42rem - 108px);
    padding-left: 7%;
    padding-right: 7%;
    .lefts {
      width: 40%;
      display: flex;
      align-items: center;
      img {
        width: 423px;
        height: 423px;
      }
    }
    .leftsc {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: end;
      img {
        width: 423px;
        height: 423px;
      }
    }
    .right {
      width: 60%;
      display: flex;
      align-items: center;

      .sub {
        width: 86%;
        h6 {
          display: block;
        }

        .mar {
          margin-top: 2rem;
        }
        h3 {
          font-family: "Montserrat";
          font-style: italic;
          font-weight: 700;
          font-size: 28px;
          line-height: 34px;
        }
        h1 {
          font-style: normal;
          font-weight: 800;
          font-size: 52px;
          line-height: 63px;
          display: flex;
          align-items: center;
          margin: 0;
          color: #5f6163;
        }
        p {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 42px;
          /* or 210% */

          margin: 0;
          display: flex;
          align-items: center;

          color: #5f6163;
        }
      }
    }
  }
}

@media only screen and (max-width: 1400px) {
  .homepaage {
    padding-left: 3%;
  }
  .Viralcards {
  }
  .viral_text {
    text-align: center;
    margin: auto;
    width: 90%;
    justify-content: center;
    // border: 1px solid black;
  }
  .viral_text > p {
    width: 100%;
  }
}

@media only screen and (max-width: 700px) {
  .custom-carsolue {
    display: block;
    margin-top: 7rem;
    height: 42rem;
  }
  .homepaage .right .sub .mar h5 {
    font-family: "Montserrat";

    font-size: 16px;
    line-height: 34px;
    display: flex;
    align-items: center;
  }
  .MarketText2 {
    width: 85%;
    margin: auto;
    text-align: center;
  }
  .MarketText2 > p {
    width: 100%;
  }
  .viral_text {
    text-align: center;
    margin: auto;
    width: 90%;
    justify-content: center;
    // border: 1px solid black;
  }
  .viral_text > p {
    width: 100%;
  }
  .custompage {
    flex-direction: column;
    height: auto;
    .lefts {
      width: 100%;
      justify-content: center;
      margin-top: 8rem;
      img {
        width: 315px;
        height: 299px;
      }
    }
    .leftsc {
      width: 100%;
      justify-content: center;
      margin-top: 8rem;
      img {
        width: 315px;
        height: 299px;
      }
    }
    .right {
      order: 2;
      width: 100%;
      display: flex;
      align-items: center;
      .sub {
        width: 100%;

        h1 {
          font-weight: 800;
          font-size: 30px;
          line-height: 37px;
          justify-content: center;
          text-align: center;
          margin-top: 2rem;
        }
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 33px;
          margin: 0;
          display: flex;
          align-items: center;
          color: #5f6163;
          justify-content: center;
          text-align: center;
        }
      }
    }
  }

  .homepaage {
    height: auto;
    .left {
      display: none;
    }
    .right {
      width: 100%;
      padding-top: 2rem;
      .sub {
        .label1 {
          justify-content: center;
          margin-right: 0;
          width: 70%;
          align-items: center;
          margin: auto;

          height: 4rem;
        }
        .label2 {
          justify-content: center;
          margin-right: 0;
          width: 70%;
          align-items: center;
          margin: auto;

          height: 4rem;
          margin-left: 20px;
        }
        .sectionlabel {
          display: flex;
          // width: 90%;
          margin: 15px 15px;
          height: 120px;
          width: 90%;
          // margin-bottom: 20px;
        }
        h1 {
          font-weight: 800;
          font-size: 35px;
          line-height: 35px;
          /* or 150% */

          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
          color: #cf1a38;
        }
        h6 {
          // font-size: 15px;
          // justify-content: center;
          // padding: 0px;
          // line-height: 36px;
          // color: #5F6163;
          // text-align: center;
          // display: flex !important;
          // font-weight:"normal";
          display: none;
        }
        p {
          display: none;
        }
      }
    }
  }
}
@media only screen and (max-width: 991.9px) {
  .Seconcards {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .Viralcards {
    display: flex;
    flex-direction: column;
  }

  .MarketText2 {
    width: 85%;
    margin: auto;
    text-align: center;
  }
  .MarketText2 > p {
    width: 100%;
  }

  .homepaage {
    background: white;
    height: auto;
    margin-top: 68px;
    padding: 0;
  }
  .rightside-cards {
    width: 100%;
    display: block;
    padding-left: 7%;
    padding-right: 7%;
    h4 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      margin-bottom: 2.5rem;
      align-items: center;
      margin-top: 2.5rem;
      color: #283790;
    }
    .cardsinside {
      height: 200px;
      width: 100%;
      margin-bottom: 1.5rem;
    }
  }
  .left-side-inide {
    padding-left: 7%;
    padding-right: 7%;
    background: linear-gradient(
      180deg,
      #283790 0%,
      rgba(40, 55, 144, 0) 174.12%
    );
    width: 100%;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    h2 {
      font-weight: 800;
      font-size: 35px;
      line-height: 40px;
      display: flex;
      align-items: center;

      color: #ffffff;
    }
    h1 {
      display: none;
    }
    h5 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 800;
      font-size: 28px;
      line-height: 34px;
      display: flex;
      align-items: center;

      color: #ffffff;
    }
    .lable-button {
      display: block;
      margin-top: 2rem;
      .user {
        height: 60px;
        width: 250px;
        font-size: 18px;
      }
      .businner {
        margin-top: 1rem;
        height: 60px;
        width: 250px;
        font-size: 18px;
        margin-left: 0px;
      }
    }
  }
}
