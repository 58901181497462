.footer{
    background-color: #CF1A38;
    display: flex;
    padding-left: 3%;
    padding-right: 8.5%;
    padding-top: 4rem;
    padding-bottom: 2rem;
   
    .left{
        width: 50%;
        img{
            width: 319px;
height: 57.08px;
margin-bottom: 2rem;
margin-left: -13px;
        }
        p{
            font-family: 'Montserrat';
font-style: normal;
font-weight: 200;
font-size: 13px;
line-height: 25px;
/* or 192% */

display: flex;
align-items: center;

color: #FFFFFF;

span{
    font-weight:bold;
    margin-right: 5px;
}
        }
    }
    .right{
        width: 50%;
        p{
            font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 22px;
display: flex;
align-items: center;
margin-bottom: 41px;

color: #FFFFFF;
cursor: pointer;
&:hover{
    transform: scale(1.1);
};
        }
    }
}

@media only screen and (max-width: 700px) {

.footer{
    display: none;
}
}