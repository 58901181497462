.Service {
  background: linear-gradient(180deg, #283790 0%, #000000 147.45%);
  display: flex;
  width: 100%;
  padding-left: 3.4%;
  padding-right: 3.4%;
  
  .innerOne {
    width: 40%;
    padding-top: 72px;
  }
  .right{
    width: 100%;
  }
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 55px;
    line-height: 55px;
    margin-bottom: 0;
    color: #ffffff;
  }
  h4 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 55px;

    color: #ffffff;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    width: 517px;
    margin-bottom: 0;
    color: #ffffff;
  }
  .innerTwo {
    display: flex;
 
    width: 100%;
    justify-content: space-between;
    border-bottom: 1.5px solid #ffffff42;
    padding-bottom: 5rem;
    padding-top: 5rem;
    &:nth-last-child(1){
        border:none
    }
  }
}
@media only screen and (max-width: 991.9px) {
.Service {
    display: block;
    .innerOne {
        width: 100%;
        padding-top: 27px;
    }
    .right {
        width: 100%;
        border-top: 1.5px solid #ffffff42;
        margin-top: 3rem;
    }
    .innerTwo {
        display: block;
        width: 100%;
        padding-top: 3rem;
    }
    p{
        width: 100%;
    }
}

}