// .navbar{
//   padding-top: 0px !important;
//   padding-bottom: 0px !important;

// }
// // .Navbar-all-btn{
// //   display: flex !important;
// //   flex-wrap: wrap !important;

// // }
// .desk{
//   display: block;
// }
// .mobo{
//   display: none;
// }
// .custom_navbar1e{

//     padding-right: 0%;
//   position: fixed;
//   background: #ffffff;
//   border-top: 0.5px solid #e5e5e5b3;
//   top:45px;
//   z-index: 999;
//       width: 100%;

//       .mobile{
//         height: 76px;
//         border-bottom: 0.5px solid #e5e5e5b3;
//         padding-left: 2% !important;
//         // border-top: 0.5px solid #e5e5e5b3

//       }
//     .navbar-toggler {
//       padding: 0.25rem 0.75rem;
//       font-size: 1.25rem;
//       line-height: 1;
//       background-color: transparent;
//       border: 0px solid transparent;
//       border-radius: 0rem;
//       transition: box-shadow 0.15s ease-in-out;
//       left: 0;
//       position: absolute;
//       top: 20px;

//     }

//            .nav-link {
//               color: #18191D;
//               margin-right: 19px !important;

//         }

//    .InputBox{
//     max-width: 304px;
//      height: 100%;
//      border-left: 0.5px solid #e5e5e5b3;
//      border-right: 0.5px solid #e5e5e5b3;
//      display:flex;
//      align-items: center;
//      justify-content: center;
//      margin-left: 50px;
//      padding: 0 10px;
//     //  background:#5F6163
//    }
//    .InputBox > input{
//     // max-width: 304px;
//     border: none;
//     color: black;
//     font-size: 20px;
//     font-weight: bold;

//    }
//    .buttons{
//     // border-left: 0.5px solid #e5e5e5b3 !important;
//    // border-bottom: 0.5px solid #e5e5e5b3 !important;
//     height: 75px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     padding: 0 30px;
//     color: #18191D;
//     max-width:159px;
//     cursor: pointer;
//     background-color: transparent;
//    }
//  .buttons:nth-child(2n+1){
//   border-left:  0.5px solid #e5e5e5b3;
//   border-right:  0.5px solid #e5e5e5b3;
//  }

//   ::placeholder {
//     color: #c5c5c6;
//     font-weight: bold;
//   }

//     // nav .navbar-collapse.show {
//     //   left:
//     // }
//     .edit{
//     display: none;
//     }
//     .navbar-brand {
//       transition: 1s;

//       &:hover {
//         transform: scale(1.1);
//       }
//     }
//     .navbar-nav{
//  background-color: white;
//     }
//     .navbar-toggler-icon {
//       background-image: url('../Image/burger.png') !important;
//       width: 19px;
//       height: 36px;
//   }
//     .nav-link {
//       font-style: normal;
//       font-weight: 400;
//       font-size: 18px;
//       line-height: 22px;
//       display: flex;
//       align-items: center;
//       text-align: right;

//       color: #18191D;
//       &:hover {
//          color: #18191D;
//         transform: scale(1.1);
//       }
//       &:active{
//          color: #18191D;
//       }
//     }
//     .custom-last-button1 {
//       width: 126px;
//       height: 76px;
//       border-radius: 0px;
//       background: #CD1E25;
//       border: none !important;

//       font-style: normal;
//       font-weight: bold;
//       font-size: 15px;
//       line-height: 15px;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       text-align: center;
//       color: #ffffff;

//       overflow: hidden;
//       position: relative;
//       cursor: pointer;
//       transition: 1s;
//       z-index: 20;
//       &:hover {
//         font-size: 15px;
//         background: #CD1E25;
//         border: 0.5px solid #CD1E25;
//       }
//       &:after {
//         background: #fff;
//         content: "";
//         height: 155px;
//         left: -75px;
//         // opacity: 1;
//         position: absolute;
//         top: -50px;
//         width: 50px;
//         -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//         transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//         -webkit-transform: rotate(35deg);
//         -ms-transform: rotate(35deg);
//         transform: rotate(35deg);
//         z-index: -10;
//       }

//       &:hover:after {
//         left: 120%;
//         -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//         transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//       }

//       &:active {
//         background: #CD1E25;
//         border: 0.5px solid #CD1E25;
//       }

//       &:focus {
//         background: #CD1E25;
//         border: 0.5px solid #CD1E25;
//         box-shadow: 0 0 0 0 #CD1E25;
//       }
//     }

//     .custom-first-buttom1 {
//       width: 126px;
//       height: 76px;
//  border: none !important;
//       background: #5F6163;
//  margin-left: 10px;
//       border-radius: 0px;
//       font-style: normal;
//       font-weight: bold;
//       font-size: 15px;
//       line-height: 15px;
//       display: flex;
//       align-items: center;
//       text-align: center;
//       color: #ffffff;
//       justify-content: center;
//       color: #ffffff;
//       overflow: hidden;
//       position: relative;
//       cursor: pointer;
//       transition: 1s;
//       z-index: 20;
//       border: 0.5px solid white;
//       &:hover {
//         font-size: 15px;
//         background: #5F6163;
//         border: 0.5px solid white;
//       }
//       &:after {
//         background: #fff;
//         content: "";
//         height: 155px;
//         left: -75px;
//         // opacity: 1;
//         position: absolute;
//         top: -50px;
//         width: 50px;
//         -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//         transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//         -webkit-transform: rotate(35deg);
//         -ms-transform: rotate(35deg);
//         transform: rotate(35deg);
//         z-index: -10;
//       }

//       &:hover:after {
//         left: 120%;
//         -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//         transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//       }

//       &:active {
//         background: #5F6163;
//         border: 0.5px solid #5F6163;
//       }

//       &:focus {
//         background: #5F6163;
//         border: 0.5px solid #5F6163;
//         box-shadow: 0 0 0 0 #5F6163;
//       }
//     }

//     .custom-last-button {
//       width: 126px;
//       height: 76px;
//       border-radius: 0px;
//       background: #CD1E25;
//       border: none !important;

//       font-style: normal;
//       font-weight: bold;
//       font-size: 15px;
//       line-height: 15px;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       text-align: center;
//       color: #ffffff;

//       overflow: hidden;
//       position: relative;
//       cursor: pointer;
//       transition: 1s;
//       z-index: 20;
//       &:hover {
//         font-size: 15px;
//         background: #CD1E25;
//         border: 0.5px solid #CD1E25;
//       }
//       &:after {
//         background: #fff;
//         content: "";
//         height: 155px;
//         left: -75px;
//         // opacity: 1;
//         position: absolute;
//         top: -50px;
//         width: 50px;
//         -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//         transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//         -webkit-transform: rotate(35deg);
//         -ms-transform: rotate(35deg);
//         transform: rotate(35deg);
//         z-index: -10;
//       }

//       &:hover:after {
//         left: 120%;
//         -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//         transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//       }

//       &:active {
//         background: #CD1E25;
//         border: 0.5px solid #CD1E25;
//       }

//       &:focus {
//         background: #CD1E25;
//         border: 0.5px solid #CD1E25;
//         box-shadow: 0 0 0 0 #CD1E25;
//       }
//     }

//     .custom-first-buttom {
//       width: 126px;
//       height: 76px;
//  border: none !important;
//       background: #5F6163;
//  margin-left: 10px;
//       border-radius: 0px;
//       font-style: normal;
//       font-weight: bold;
//       font-size: 15px;
//       line-height: 15px;
//       display: flex;
//       align-items: center;
//       text-align: center;
//       color: #ffffff;
//       justify-content: center;
//       color: #ffffff;
//       overflow: hidden;
//       position: relative;
//       cursor: pointer;
//       transition: 1s;
//       z-index: 20;
//       border: 0.5px solid white;
//       &:hover {
//         font-size: 15px;
//         background: #5F6163;
//         border: 0.5px solid white;
//       }
//       &:after {
//         background: #fff;
//         content: "";
//         height: 155px;
//         left: -75px;
//         // opacity: 1;
//         position: absolute;
//         top: -50px;
//         width: 50px;
//         -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//         transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//         -webkit-transform: rotate(35deg);
//         -ms-transform: rotate(35deg);
//         transform: rotate(35deg);
//         z-index: -10;
//       }

//       &:hover:after {
//         left: 120%;
//         -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//         transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//       }

//       &:active {
//         background: #5F6163;
//         border: 0.5px solid #5F6163;
//       }

//       &:focus {
//         background: #5F6163;
//         border: 0.5px solid #5F6163;
//         box-shadow: 0 0 0 0 #5F6163;
//       }
//     }
//   }

//   .custom_navbar {
//     padding-left: 4%;
//     padding-right: 4%;
//   display: none;
//     background: #5F6163;
//       width: 100%;
//     .navbar-toggler {
//       padding: 0.25rem 0.75rem;
//       font-size: 1.25rem;
//       line-height: 1;
//       background-color: transparent;
//       border: 0px solid transparent;
//       border-radius: 0rem;
//       transition: box-shadow 0.15s ease-in-out;
//       left: 0;
//       position: absolute;
//       top: 20px;
//     }

//     // nav .navbar-collapse.show {
//     //   left: 0;
//     // }

//     .navbar-brand {
//       transition: 1s;

//       &:hover {
//         transform: scale(1.1);
//       }
//     }
//     .nav-link {
//       font-style: normal;
//       font-weight: 500;
//       font-size: 15px;
//       line-height: 30px;
//       /* or 214% */
//       padding: 0.5rem 2rem;
//       display: block;
//       align-items: center;
//       transition: 1s;
//       color: white;
//       &:hover {
//         color: white;
//         transform: scale(1.1);
//       }
//     }
//     .custom-last-button {
//       width: 102px;
//       height: 36px;

//       background: #CD1E25;
//       border: 0.5px solid #CD1E25;
//       border-radius: 10px;
//       font-style: normal;
//       font-weight: bold;
//       font-size: 12px;
//       line-height: 15px;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       text-align: center;
//       color: #ffffff;
//       margin-left: 1rem;
//       overflow: hidden;
//       position: relative;
//       cursor: pointer;
//       transition: 1s;
//       z-index: 20;
//       &:hover {
//         font-size: 15px;
//         background: #CD1E25;
//         border: 0.5px solid #CD1E25;
//       }
//       &:after {
//         background: #fff;
//         content: "";
//         height: 155px;
//         left: -75px;
//         // opacity: 1;
//         position: absolute;
//         top: -50px;
//         width: 50px;
//         -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//         transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//         -webkit-transform: rotate(35deg);
//         -ms-transform: rotate(35deg);
//         transform: rotate(35deg);
//         z-index: -10;
//       }

//       &:hover:after {
//         left: 120%;
//         -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//         transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//       }

//       &:active {
//         background: #CD1E25;
//         border: 0.5px solid #CD1E25;
//       }

//       &:focus {
//         background: #CD1E25;
//         border: 0.5px solid #CD1E25;
//         box-shadow: 0 0 0 0 #CD1E25;
//       }
//     }

//     .custom-first-buttom {
//       width: 102px;
//       height: 36px;

//       background: #5F6163;
//       border: 0.5px solid #5F6163;
//       border-radius: 10px;
//       font-style: normal;
//       font-weight: bold;
//       font-size: 12px;
//       line-height: 15px;
//       display: flex;
//       align-items: center;
//       text-align: center;
//       color: #ffffff;
//       justify-content: center;
//       color: #ffffff;
//       overflow: hidden;
//       position: relative;
//       cursor: pointer;
//       transition: 1s;
//       border: 0.5px solid white;
//       z-index: 20;
//       &:hover {
//         font-size: 15px;
//         background: #5F6163;
//         border: 0.5px solid white;
//       }
//       &:after {
//         background: #fff;
//         content: "";
//         height: 155px;
//         left: -75px;
//         // opacity: 1;
//         position: absolute;
//         top: -50px;
//         width: 50px;
//         -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//         transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//         -webkit-transform: rotate(35deg);
//         -ms-transform: rotate(35deg);
//         transform: rotate(35deg);
//         z-index: -10;
//       }

//       &:hover:after {
//         left: 120%;
//         -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//         transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
//       }

//       &:active {
//         background: #5F6163;
//         border: 0.5px solid #5F6163;
//       }

//       &:focus {
//         background: #5F6163;
//         border: 0.5px solid #5F6163;
//         box-shadow: 0 0 0 0 #5F6163;
//       }
//     }
//   }

//   @media only screen and (max-width: 991.9px)  {
// .desk{
//   display: none;
// }
// .mobo{
//   display: block;
// }
//     .navbar-toggler:focus {
//       text-decoration: none;
//       outline: 0;
//       box-shadow: 0 0 0 0rem !important;
//   }

//   .navbar-nav {
//     display: flex;
//     flex-direction: column;
//     padding-left: 0;
//     margin-bottom: 0;
//     list-style: none;
//     padding-top: 3rem;
// }
//   .custom_navbar1e {

//      .nav-link {
//       font-style: normal;
//       font-weight: 500;
//       font-size: 26px;
//       line-height: 30px;
//       padding: 0;
//       display: block;
//       align-items: center;
//       transition: 1s;
//       color: #18191D;
//       justify-content: center;
//       margin: 0;
//       margin-bottom: 22px;
//       text-align: center;
//   }
//     .mobile{
//       padding-left: 0% !important;
//       padding-right: 0% !important;
//       color:#18191D !important;
//     }
//        .custom-last-button {
// display: none;
//     }
//     .custom-last-button1 {
//       display: none;
//     }
//     .custom-first-buttom1
//     {
//       margin: 0;
//       font-style: normal;
//       font-weight: 500;
//       font-size: 26px;
//       line-height: 30px;
//       /* padding: 0.5rem 2rem; */
//       display: block;
//       align-items: center;
//       transition: 1s;
//       color: #18191D;
//       justify-content: center;
//       margin: 0;
//       margin-bottom: 22px;
//       width: auto;
//       background: transparent;
//       margin-top: 0px;
//       height: auto;
//       padding-left: 0;
//       &:focus{
//         margin: 0;
//         font-style: normal;
//         font-weight: 500;
//         font-size: 26px;
//         line-height: 30px;
//         /* padding: 0.5rem 2rem; */
//         display: block;
//         align-items: center;
//         transition: 1s;
//         color: #18191D;
//         justify-content: center;
//         margin: 0;
//         margin-bottom: 22px;
//         width: auto;
//         background: transparent;
//         margin-top: 0px;
//         height: auto;
//         padding-left: 0;
//       }
//       &:active{
//         margin: 0;
//         font-style: normal;
//         font-weight: 500;
//         font-size: 26px;
//         line-height: 30px;
//         /* padding: 0.5rem 2rem; */
//         display: block;
//         align-items: center;
//         transition: 1s;
//         color: #18191D;
//         justify-content: center;
//         margin: 0;
//         margin-bottom: 22px;
//         width: auto;
//         background: transparent;
//         margin-top: 0px;
//         height: auto;
//         padding-left: 0;
//       }
//     }
//     .custom-first-buttom {

//       position: absolute;
//       bottom: 0;
//       width: 60%;
//       margin: 0;
//       bottom: 45px;
//       background: #CD1E25;
//       border-radius: 43px;
//       font-size: 18px;
//       margin: auto;
//     }

//     nav {
//     //   .navbar-collapse.show {
//     //     left: 0px;
//     // height: 50vh;
//     // margin-top: 3rem;
//     // background: white;
//     // width: 100%;
//     // max-width: 100%;
//     // }
//         .navbar-collapse.show {
//           padding-left: 0%;
//    background: white;
//    z-index: 9999999;
//    background: white;
//     z-index: 9999999;
//     position: fixed;
//     top: 0;
//     margin-top: 105px;
//     right: 0;
//     width: 100%;
//     display: block;
//     align-items: center;
//     width: 100%;
//     justify-content: center;
//     height: calc(100vh - 64px);
//     padding-bottom: 74px;
//      }
//     .navbar-toggler {
//       padding: 0.25rem 0.75rem;
//       font-size: 1.25rem;
//       line-height: 1;
//       background-color: transparent;
//       border: 0px solid transparent;
//       border-radius: 0rem;
//       transition: box-shadow 0.15s ease-in-out;
//       left:0px;
//       position: relative;
//       top: 0px;
//   }
//     .edit{
//       display: block;
//       position: absolute;
//       top: 0;
//       width: 209px;
//       bottom: 0;
//       position: absolute;
//       top: 50%;
//       left: 50%;
//       -moz-transform: translateX(-50%) translateY(-51%);
//       -webkit-transform: translateX(-50%) translateY(-51%);
//       transform: translateX(-50%) translateY(-51%);
//     }
//     }
//     .custom-first-buttom {

//     }
//     .custom-last-button {
//       margin-left: 28px;
//     }
//   .navbar-brand {
//     transition: 1s;
//     display: none;
// }
//   }
//   }

//   @media only screen and (max-width: 1000px)  {
//     .InputBox{
//         display: none !important;
//     }
//     .InputBox > input{
//       overflow: hidden;
//     }
//   }

.navbar {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-top: 0.5px solid #e5e5e5;
}

.the-nav-card {
  width: 100%;
  height: 70px;
  background: white;
  border: 1px solid black;
}

.desk {
  display: block;
}

.mobo {
  display: none;
}

.vl-top {
  border-left: 0.5px solid #e5e5e5;
  height: 100%;
  z-index: 10;
}

.searchsty {
  font-weight: 700;
  font-size: 16px;
  line-height: 45px;
  display: flex;
  align-items: center;
  color: #5f6163;
  opacity: 0.25;
  margin-left: 20px;
  border: none;
  width: 25%;
  margin-top: 8px;
}

.vl-topnav {
  border-left: 0.5px solid #e5e5e5;
  height: 66px;
  max-width: 117px;
}

.active {
  margin-left: 20px !important;
  margin-right: 20px !important;
  // padding: 0px 10px;
  font-size: 15px !important;
  // font-weight: 800 !important;
}

.menu-items {
  justify-content: center;
  margin-left: 22px;
  margin-right: 22px;
  font-weight: 400;
  text-decoration: none;
  margin-top: 25px;
  color: black;
  padding: 0px 8px;
  font-size: 15px;
}
.menu-items:hover {
  color: black;
  font-weight: bold;
}
.active_custom {
  // font-weight: bold;
}
.btns {
  background: #283790 !important;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 700;
  color: #fff;
  position: relative;
  margin-bottom: -2px;  
}
.custom_navbar1e {
  padding-right: 0%;
  position: fixed;
  background: #ffffff;

  top: 39px;
  z-index: 99;
  width: 100%;

  .mobile {
    height: 67px;
    border-bottom: 0.5px solid #e5e5e5b3;
    padding-left: 43px !important;
    // padding-right: 7% !important;
  }

  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 0px solid transparent;
    border-radius: 0rem;
    transition: box-shadow 0.15s ease-in-out;
    left: 0;
    position: absolute;
    top: 20px;
  }

  .nav-link {
    color: black !important;
    // margin-right: 19px !important;
  }

  // nav .navbar-collapse.show {
  //   left:
  // }
  .edit {
    display: none;
  }

  .navbar-brand {
    transition: 1s;

    &:hover {
      transform: scale(1.1);
    }
  }

  .navbar-nav {
    background-color: white;
  }

  .navbar-toggler-icon {
    background-image: url("../Image/burger.png") !important;
    width: 19px;
    height: 36px;
  }

  .nav-link {
    // font-style: normal;
    // font-weight: 400;
    // font-size: 18px;
    // line-height: 22px;
    // display: flex;
    // align-items: center;
    // text-align: right;

    // color: #5F6163;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;

    color: #334480 !important;

    &:hover {
      color: #334480 !important;
      transform: scale(1.1);
    }

    &:active {
      color: #334480 !important;
    }
  }

  .custom-last-button1 {
    width: 126px;
    height: 76px;
    border-radius: 0px;
    background: #cd1e25;
    border: none !important;

    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;

    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: 1s;
    z-index: 20;

    &:hover {
      font-size: 15px;
      background: #cd1e25;
      border: 0.5px solid #cd1e25;
    }

    &:after {
      background: #fff;
      content: "";
      height: 155px;
      left: -75px;
      opacity: 1;
      position: absolute;
      top: -50px;
      width: 50px;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      -webkit-transform: rotate(35deg);
      -ms-transform: rotate(35deg);
      transform: rotate(35deg);
      z-index: -10;
    }

    &:hover:after {
      left: 120%;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:active {
      background: #cd1e25;
      border: 0.5px solid #cd1e25;
    }

    &:focus {
      background: #cd1e25;
      border: 0.5px solid #cd1e25;
      box-shadow: 0 0 0 0 #cd1e25;
    }
  }

  .custom-first-buttom1 {
    width: 126px;
    height: 76px;
    border: none !important;
    background: #5f6163;
    margin-left: 10px;
    border-radius: 0px;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    justify-content: center;
    color: #ffffff;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: 1s;
    z-index: 20;
    border: 0.5px solid white;

    &:hover {
      font-size: 15px;
      background: #5f6163;
      border: 0.5px solid white;
    }

    &:after {
      background: #fff;
      content: "";
      height: 155px;
      left: -75px;
      opacity: 1;
      position: absolute;
      top: -50px;
      width: 50px;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      -webkit-transform: rotate(35deg);
      -ms-transform: rotate(35deg);
      transform: rotate(35deg);
      z-index: -10;
    }

    &:hover:after {
      left: 120%;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:active {
      background: #5f6163;
      border: 0.5px solid #5f6163;
    }

    &:focus {
      background: #5f6163;
      border: 0.5px solid #5f6163;
      box-shadow: 0 0 0 0 #5f6163;
    }
  }

  .custom-last-button {
    width: 126px;
    height: 76px;
    border-radius: 0px;
    background: #cd1e25;
    border: none !important;

    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;

    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: 1s;
    z-index: 20;

    &:hover {
      font-size: 15px;
      background: #cd1e25;
      border: 0.5px solid #cd1e25;
    }

    &:after {
      background: #fff;
      content: "";
      height: 155px;
      left: -75px;
      opacity: 1;
      position: absolute;
      top: -50px;
      width: 50px;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      -webkit-transform: rotate(35deg);
      -ms-transform: rotate(35deg);
      transform: rotate(35deg);
      z-index: -10;
    }

    &:hover:after {
      left: 120%;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:active {
      background: #cd1e25;
      border: 0.5px solid #cd1e25;
    }

    &:focus {
      background: #cd1e25;
      border: 0.5px solid #cd1e25;
      box-shadow: 0 0 0 0 #cd1e25;
    }
  }

  .custom-first-buttom {
    width: 126px;
    height: 76px;
    border: none !important;
    background: #5f6163;
    margin-left: 10px;
    border-radius: 0px;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    justify-content: center;
    color: #ffffff;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: 1s;
    z-index: 20;
    border: 0.5px solid white;

    &:hover {
      font-size: 15px;
      background: #5f6163;
      border: 0.5px solid white;
    }

    &:after {
      background: #fff;
      content: "";
      height: 155px;
      left: -75px;
      opacity: 1;
      position: absolute;
      top: -50px;
      width: 50px;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      -webkit-transform: rotate(35deg);
      -ms-transform: rotate(35deg);
      transform: rotate(35deg);
      z-index: -10;
    }

    &:hover:after {
      left: 120%;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:active {
      background: #5f6163;
      border: 0.5px solid #5f6163;
    }

    &:focus {
      background: #5f6163;
      border: 0.5px solid #5f6163;
      box-shadow: 0 0 0 0 #5f6163;
    }
  }
}

.custom_navbar {
  padding-left: 4%;
  padding-right: 4%;
  display: none;
  background: #5f6163;
  width: 100%;

  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 0px solid transparent;
    border-radius: 0rem;
    transition: box-shadow 0.15s ease-in-out;
    left: 0;
    position: absolute;
    top: 20px;
  }

  // nav .navbar-collapse.show {
  //   left: 0;
  // }

  .navbar-brand {
    transition: 1s;

    &:hover {
      transform: scale(1.1);
    }
  }

  .nav-link {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 30px;
    /* or 214% */
    padding: 0.5rem 2rem;
    display: block;
    align-items: center;
    transition: 1s;
    color: white;

    &:hover {
      color: white;
      transform: scale(1.1);
    }
  }

  .custom-last-button {
    width: 102px;
    height: 36px;

    background: #cd1e25;
    border: 0.5px solid #cd1e25;
    border-radius: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
    margin-left: 1rem;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: 1s;
    z-index: 20;

    &:hover {
      font-size: 15px;
      background: #cd1e25;
      border: 0.5px solid #cd1e25;
    }

    &:after {
      background: #fff;
      content: "";
      height: 155px;
      left: -75px;
      opacity: 1;
      position: absolute;
      top: -50px;
      width: 50px;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      -webkit-transform: rotate(35deg);
      -ms-transform: rotate(35deg);
      transform: rotate(35deg);
      z-index: -10;
    }

    &:hover:after {
      left: 120%;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:active {
      background: #cd1e25;
      border: 0.5px solid #cd1e25;
    }

    &:focus {
      background: #cd1e25;
      border: 0.5px solid #cd1e25;
      box-shadow: 0 0 0 0 #cd1e25;
    }
  }

  .custom-first-buttom {
    width: 102px;
    height: 36px;

    background: #5f6163;
    border: 0.5px solid #5f6163;
    border-radius: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    justify-content: center;
    color: #ffffff;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: 1s;
    border: 0.5px solid white;
    z-index: 20;

    &:hover {
      font-size: 15px;
      background: #5f6163;
      border: 0.5px solid white;
    }

    &:after {
      background: #fff;
      content: "";
      height: 155px;
      left: -75px;
      opacity: 1;
      position: absolute;
      top: -50px;
      width: 50px;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      -webkit-transform: rotate(35deg);
      -ms-transform: rotate(35deg);
      transform: rotate(35deg);
      z-index: -10;
    }

    &:hover:after {
      left: 120%;
      -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:active {
      background: #5f6163;
      border: 0.5px solid #5f6163;
    }

    &:focus {
      background: #5f6163;
      border: 0.5px solid #5f6163;
      box-shadow: 0 0 0 0 #5f6163;
    }
  }
}

@media screen and (min-width: 1600px) {
  .img-space {
    padding-right: 4vw;
  }

  .active {
    margin-left: 30px !important;
    margin-right: 30px !important;
    // padding: 0px 10px;
  }

  .menu-items {
    justify-content: center;
   margin-left: 37px;
    margin-right: 37px;
  }

  .custom_navbar1e {
    .mobile {
      padding-left: 58px !important;
      // padding-right: 7% !important;
    }
  }
}
@media only screen and (max-width: 1400px) {
  .menu-items {
    justify-content: center;
    margin-left: 8px;
    margin-right: 8px;
  }
  .btns {
    width: 80px;
  }
  .custom_navbar1e {
    .mobile {
      padding-left: 25px !important;
      // padding-right: 7% !important;
    }
  }
}

@media only screen and (max-width: 991.9px) {
  .desk {
    display: none;
  }

  .mobo {
    display: block;
  }

  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0rem !important;
  }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    padding-top: 1rem !important;
    padding-left: 7% !important;
  }

  .custom_navbar1e {
    top: 0px;

    .nav-link {
      margin-bottom: 2rem;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;

      color: #334480;
    }

    .mobile {
      padding-left: 0% !important;
      padding-right: 0% !important;
    }

    .custom-last-button {
      display: none;
    }

    .custom-last-button1 {
      display: none;
    }

    .custom-first-buttom1 {
      margin: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 30px;
      /* padding: 0.5rem 2rem; */
      display: block;
      align-items: center;
      transition: 1s;
      color: #5f6163;
      justify-content: center;
      margin: 0;
      margin-bottom: 22px;
      width: auto;
      background: transparent;
      margin-top: 0px;
      height: auto;
      padding-left: 0;

      &:focus {
        margin: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 30px;
        /* padding: 0.5rem 2rem; */
        display: block;
        align-items: center;
        transition: 1s;
        color: #5f6163;
        justify-content: center;
        margin: 0;
        margin-bottom: 22px;
        width: auto;
        background: transparent;
        margin-top: 0px;
        height: auto;
        padding-left: 0;
      }

      &:active {
        margin: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 30px;
        /* padding: 0.5rem 2rem; */
        display: block;
        align-items: center;
        transition: 1s;
        color: #5f6163;
        justify-content: center;
        margin: 0;
        margin-bottom: 22px;
        width: auto;
        background: transparent;
        margin-top: 0px;
        height: auto;
        padding-left: 0;
      }
    }

    .custom-first-buttom {
      position: absolute;
      bottom: 0;
      width: 60%;
      margin: 0;
      bottom: 45px;
      background: #cd1e25;
      border-radius: 43px;
      font-size: 18px;
      margin: auto;
    }

    nav {
      //   .navbar-collapse.show {
      //     left: 0px;
      // height: 50vh;
      // margin-top: 3rem;
      // background: white;
      // width: 100%;
      // max-width: 100%;
      // }
      .navbar-collapse.show {
        padding-left: 0%;
        background: white;
        z-index: 9999999;
        background: white;
        z-index: 9999999;
        position: fixed;
        top: 0;
        margin-top: 65px;
        right: 0;
        width: 100%;
        display: block;
        align-items: center;
        width: 100%;
        justify-content: center;
        height: calc(100vh - 64px);
        padding-bottom: 74px;
      }

      .navbar-toggler {
        padding: 0.25rem 0.75rem;
        font-size: 1.25rem;
        line-height: 1;
        background-color: transparent;
        border: 0px solid transparent;
        border-radius: 0rem;
        transition: box-shadow 0.15s ease-in-out;
        left: 1.3%;
        position: relative;
        top: 0px;
      }

      .edit {
        display: block;
        position: absolute;
        top: 0;
        width: 209px;
        bottom: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -moz-transform: translateX(-50%) translateY(-51%);
        -webkit-transform: translateX(-50%) translateY(-51%);
        transform: translateX(-50%) translateY(-51%);
      }
    }

    .custom-first-buttom {
    }

    .custom-last-button {
      margin-left: 28px;
    }

    .navbar-brand {
      transition: 1s;
      display: none;
    }
  }
}
@media only screen and (max-width: 700px) {
  .custom_navbar1e nav .edit {
    display: block;
    position: absolute;
    top: 0;
    width: 209px;
    bottom: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-51%);
    -webkit-transform: translateX(-50%) translateY(-51%);
    transform: translateX(-50%) translateY(-51%);
  }
}
