.brand-banner{
    width:100vw;   
    height: 157px;
    background: #283790;
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding:0 70px ;
  
   
    .Cards{
        h1{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 65px;
            line-height: 160%;
            /* or 104px */
            
            display: flex;
            align-items: center;
            letter-spacing: 0.002em;
            justify-content: center;
            color: #FFFFFF;
           

        }
        p{
            font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height:0;
/* or 26px */

// display: flex;
// align-items: center;
text-align: center;
letter-spacing: 0.002em;

color: #FFFFFF;

        }
        
    }
}
@media only screen and (min-width: 800px) and (max-width: 1400px){
    .brand-banner{    
        //  border: 0.5px solid #E5E5E5;
          padding-left:40px;
          padding-right:40px;
        
      }
}
// @media only screen and (max-width: 1400px) {
//     .brand-banner{    
//         //  border: 0.5px solid #E5E5E5;
//           padding-left:40px;
//           padding-right:40px;
        
//       }
      
// }

@media only screen and (max-width: 700px) {
    .brand-banner{    
        //  border: 0.5px solid #E5E5E5;
        //   padding-left:40px;
        //   padding-right:40px;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: repeat(2,1fr);
        // height: 400px;
        width: 100%;
        
    
        
      }
      .Cards{
        // border: 1px solid;   
        text-align: center;
        width: 100%;
        height: 100%;
        margin-top: 10%;
        
      }
      .brand-banner{
       
        .Cards{
           
            h1{
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 45px;
                line-height: 160%;
                /* or 104px */
               
                display: flex;
                align-items: center;
                letter-spacing: 0.002em;
                justify-content: center;
                color: #FFFFFF;
               
    
            }
           
            
        }
    }
    .brand-banner{
        width:100vw;   
    height: auto;
    background: #CF1A38;
    // display:flex;
    justify-content: space-between;
    align-items: center;
    padding:0 4px ;
    padding-right: 10%;
    }
     
}