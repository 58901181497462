.main{
     margin-top: 7%;
}
.nav-bar2{
    width: 100%;
    height: 30px;
    border: 1px solid #e5e5e5b3;
    /* margin: auto;
  position: fixed;
  background-color: white; */
 
}
.nav-bar2-inside{

    display: flex;
    width: 40%;
    /* border: 1px solid; */
    display: flex;
    justify-content: space-around;
    /* margin-left: 1%; */
    margin-top: 5px;
    font-size: 13px;
}
.affiliate{
    width: 100%;
    height: 613px;
    /* border: 1px solid; */
   display: flex;
}
.affiliate-left{
    width: 60%;
    /* border: 1px solid; */
    height: 100%;
}
.affiliate-left-inside{
    margin: 77px 85px;
    height: 150px;
    width: 50%;
    /* border: 1px solid; */
    padding: 20px;
    line-height: 29px;

   
}
.affiliate-left-inside>span{
    font-size: 50px;
    line-height: 50px;
    color: #18191D;
}
.affiliate-text{
    /* border: 1px solid; */
    margin: 45px 0px;
    height: 193px;
    width: 100%;
    line-height: 40px;
    color: #18191D;
}
.affiliate-right{
    width: 40%;
background-color: #08152D;
padding: 261px 120px;
}
.affiliate-button{
    margin: 150px 106px;
    border-radius: 18%;
    width: 14%;
    height: 45px;
    background-color: #08152D;
    border: none;
    color: white;
    font-weight: bold;
    
}
.anchor-link{
    text-decoration: none;
    color: black;
}
.anchor-link:hover{
color: black;
font-weight: bold;
}
.bolder{
 font-weight: bold;
}



/* Connection */

.connection{
    width: 100%;
    height: 640px;
    border: 1px solid #e5e5e5b3;
   display: flex;
   
}
.connection-left{

    width: 40%;
    background-color: #009efc;
    padding: 320px 64px;
}
.connection-right{
    width: 60%;
    /* border: 1px solid; */
    height: 100%;
}
.connection-right-inside{
    margin: 105px 165px;
    height: 150px;
    width: 50%;
    /* border: 1px solid; */
    padding: 20px;
    line-height: 29px;
}
.connection-right-inside>span{
    font-size: 50px;
    line-height: 50px;
    color:#009efc ;
}
.connection-text{
    margin: 45px 0px;
    height: 193px;
    width: 100%;
    line-height: 40px;
}
.connection-button{
    margin: 120px 186px;
    border-radius: 18%;
    width: 14%;
    height: 45px;
    background-color:#009efc ;
    color: white;
    border: none;
    font-weight: bold;
}


.engagement{
    width: 100%;
    height: 613px;
    /* border: 1px solid; */
   display: flex;
}
.engagement-left{
    width: 60%;
    /* border: 1px solid; */
    height: 100%;
}
.engagement-left-inside{
    margin: 77px 85px;
    height: 150px;
    width: 55%;
    /* border: 1px solid; */
    color: #3E3E41;
    padding: 20px;
    line-height: 29px;
}
.engagement-left-inside>span{
    font-size: 40px;
    line-height: 60px;
}
.engagement-text{
    margin: 50px 0px;
    height: 193px;
    width: 100%;
    line-height: 40px;
}
.engagement-right{
    width: 40%;
    background-color: #3E3E41;
    padding: 283px 120px;
}
.engagement-button{
    margin: 145px 106px;
    border-radius: 18%;
    width: 14%;
    height: 45px;
    background-color: #3E3E41;
    color: white;
    border: none;
    font-weight: bold;
}



.linked{
    width: 100%;
    height: 640px;
    border: 1px solid #e5e5e5b3;
   display: flex; 
}
.linked-right{
    width: 60%;
    /* border: 1px solid; */
    height: 100%;
}

.linked-right-inside{
    margin: 105px 165px;
    height: 150px;
    width: 50%;
    /* border: 1px solid; */
    padding: 20px;
    line-height: 29px;
}
.linked-right-inside>span{
    font-size: 42px;
    line-height: 50px;
    color: #29364D;
   
}
.linked-text{
    margin: 45px 0px;
    height: 193px;
    width: 100%;
    line-height: 35px;
}
.linked-button{
    margin: 165px 186px;
    border-radius: 18%;
    width: 14%;
    height: 45px;
    background-color:#29364D;
    color: white;
    border: none;
    font-weight: bold;
        
}
.linked-left{
    width: 40%;
    background-color:#29364D ;
    padding: 310px 80px;
}
.submain{
   overflow: scroll;
   overflow-y: hidden;
    /* width: 100%;
    height: 2400PX;
    overflow: hidden;
    margin-top: 90px;
    border: 10px solid; */
}


@media only screen and (min-width: 0px) and (max-width: 680px){
    /* .main{
        margin-top: 60%;
     } */
}

@media only screen and (max-width: 700px){
    /* .main{
        margin-top: 10%;
     } */
     .nav-bar2{
        width: 100%;
        height: 30px;
        border: 1px solid #e5e5e5b3;
        /* margin: auto; */
      /* position: fixed;
      background-color: white; */
     
    }
    .nav-bar2-inside{
    
        display: flex;
        width: 100%;
        border: 1px solid #e5e5e5b3 ;
        display: flex;
        justify-content: space-around;
       
        margin-top: 40px;
        font-size: 13px;
     
    }
    .affiliate{
        width: 100%;
        margin: auto;
        height: 613px;
        /* border: 1px solid; */
       display: inline-block;
    }
    .affiliate-left{
        width: auto;
        /* border: 1px solid; */
        height: 100%;
    }
    .affiliate-left-inside{
        margin: 77px 45px;
        height: 150px;
        width: auto;
        /* border: 1px solid; */
        padding: 20px;
        line-height: 29px;
    
       
    }
    .affiliate-left-inside>span{
        font-size: 40px;
        line-height: 35px;
        color: #18191D;
    }
    .affiliate-text{
        /* border: 1px solid; */
        margin: 45px 0px;
        height: 193px;
        width: auto;
        line-height: 35px;
        color: #18191D;
    }
    .affiliate-right{
        width: 100%;
        height: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
    background-color: #08152D;
  
    }
    .affiliate-button{
        margin: 253% 15%;
        border-radius: 18%;
        width: auto;
        height: 45px;
        background-color: #08152D;
        border: none;
        color: white;
        font-weight: bold;
        
    }



    /* jckdkhkfhfh */

    
    .connection{
        width: 100%;
        height: 640px;
        border: 1px solid #e5e5e5b3;
       display: inline-block;
       
    }
    .connection-left{
    
        width: 100%;
        background-color: #009efc;
        /* padding: 320px 64px;
         */
         margin-top: 85%;
         height: 50%;   
         display: flex;
         justify-content: center;
         align-items: center;
    }
    #image{
        background-size: cover;
        /* width: 100%; */
        height: 40px;
        
    }
    .connection-right{
        width: 60%;
        /* border: 1px solid; */
        height: 100%;
    }
    .connection-right-inside{
        margin: 55px 44px;
        height: 150px;
        width: 50%;
        /* border: 1px solid; */
        padding: 20px;
        line-height: 29px;
    }
    .connection-right-inside>span{
        font-size: 50px;
        line-height: 50px;
        color:#009efc ;
    }
    .connection-text{
        margin: 45px 0px;
        /* height: 203px; */
        width: 350%;
        line-height: 29px;
        
    }
  
    .connection-button{
        margin: 320px 66px;
        border-radius: 18%;
        width: auto;
        height: 45px;
        background-color:#009efc ;
        color: white;
        border: none;
        font-weight: bold;
    }

    .engagement{
        width: 100%;
        height: 613px;
        /* border: 1px solid; */
       display: inline-block;
    }
    .engagement-left{
        width: 60%;
        /* border: 1px solid; */
        height: 100%;
    }
    .engagement-left-inside{
        margin: 67px 45px;
        height: 150px;
        width: 55%;
        /* border: 1px solid; */
        color: #3E3E41;
        padding: 20px;
        line-height: 29px;
    }
    .engagement-left-inside>span{
        font-size: 45px;
        line-height: 38px;
    }
    .engagement-text{
        margin: 40px 0px;
        /* height: 300%; */
        width: 100%;
        line-height: 30px;
    }
    .engagement-right{
        width: 40%;
        background-color: #3E3E41;
        padding: 283px 120px;
    }
    .engagement-button{
        margin: 355px 64px;
        border-radius: 18%;
        width: auto;
        height: 45px;
        background-color: #3E3E41;
        color: white;
        border: none;
        font-weight: bold;
    }
    
    .linked{
        width: 100%;
        height: 640px;
        border: 1px solid #e5e5e5b3;
       display: inline-block; 
    }
    .linked-right{
        width: 60%;
        /* border: 1px solid; */
        height: 100%;
    }
    
    .linked-right-inside{
        margin: 105px 165px;
        height: 150px;
        width: 50%;
        /* border: 1px solid; */
        padding: 20px;
        line-height: 29px;
    }
    .linked-right-inside>span{
        font-size: 42px;
        line-height: 50px;
        color: #29364D;
       
    }
    .linked-text{
        margin: 45px 0px;
        height: 193px;
        width: 100%;
        line-height: 35px;
    }
    .linked-button{
        margin: 165px 186px;
        border-radius: 18%;
        width: 14%;
        height: 45px;
        background-color:#29364D;
        color: white;
        border: none;
        font-weight: bold;
            
    }
    .linked-left{
        width: 40%;
        background-color:#29364D ;
        padding: 310px 80px;
        margin-top: 96%;
    }


    
}

@media only screen and (max-width: 1400px){
    /* .main{
       margin-top: 9%;
    } */

}