.profolio{
    display: flex;
    margin-top: 108px;
    .left{
        width: 25%;
        border-right: 0.5px solid #EBEBEB;
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100vh - 108px);
        
        .listitem{
           

            h1{
                font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 22px;
display: flex;
align-items: center;
text-align: center;
margin-bottom: 2rem;
color: #5F6163;

            }
        }
    }
    
    .right{
        height: calc(100vh - 108px);
        overflow: scroll;
        display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 75%;
    padding-right: 8.8%;
    padding-left: 5%;
    overflow: scroll;
    display: flex;
flex-wrap: wrap;
justify-content: space-between;
    .assad{
        height: 100%;
        overflow: scroll;
        display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    }
    .skeleton
{
    width: 500px;
  
    background-color: #d9d9d9;;
    animation: glow-dim 1.5s infinite;
   
    height: 234px;
    width: 47%;
    border: 0.5px solid #EBEBEB;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}

        .cards{
            width: 47%;
height: 234px;
background: #FFFFFF;
border: 0.5px solid #EBEBEB;
border-radius: 15px;
display: flex;
justify-content: center;
align-items: center;
margin-top: 5%;
cursor: pointer;
&:hover{
    img{
    transform: scale(1.1);
    }
}
img{
    width: 80%;
height: auto;
}
        }
    }
}





@keyframes glow-dim{
    0%{opacity: 1;}
    50%{opacity: 0.6;}
    100%{opacity: 1;}
}

