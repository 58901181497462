.sidemenu {
    width: 550px;
    height: 670px;
    position: fixed;
    background: white;
    margin-top: 0px;
    padding-top: 25px;
    z-index: 11000;
    text-align: start;
    border: 1px solid #EDEDED;
    margin-top: 0px;
    // transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    margin-left: 70px;
    border: 0.5px solid #E5E5E5;
    border-radius: 15px;
}

.pagemask {
    position: fixed !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 11;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    overflow: hidden !important;
}

.close-img {
    margin-top: -138px;
    margin-left: -30px;
}

.side-text {
    margin-left: 50px;
}

.card-title {
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #5F6163;
}

.card-desc {
    font-weight: 300;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: #5F6163;
}

.card-data {
    display: flex;
    transition: -webkit-transform .5s cubic-bezier(.4, 0, .2, 1);
    transition: transform .5s cubic-bezier(.4, 0, .2, 1);
    transition: transform .5s cubic-bezier(.4, 0, .2, 1),
        -webkit-transform .5s cubic-bezier(.4, 0, .2, 1);
}

.card-data:hover {
    transform: scale(1.1);
    transition: 0.4s all ease;
    // transition: -webkit-transform .5s cubic-bezier(.4,0,.2,1);
    // transition: transform .5s cubic-bezier(.4,0,.2,1);
    // transition: transform .5s cubic-bezier(.4,0,.2,1),-webkit-transform .5s cubic-bezier(.4,0,.2,1);}
}

.company-logo {
    border: 0.5px solid #E5E5E5;
    border-radius: 5px;
    padding: 13px;
    width: 52px;
    height: 52px;
}

.card-text {
    margin-left: 10px;
}

.comp-title {
    font-weight: 700;
    font-size: 17px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #5F6163;
    margin-top: 10px;

}

.comp-desc {
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: #5F6163;
    margin-top: -11px;
}

.rightmenu {
    width: 550px;
    height: 520px;
    position: fixed;
    background: white;
    padding-top: 25px;
    z-index: 11;
    text-align: start;
    border: 1px solid #EDEDED;
    margin-top: 574px;
    transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    margin-right: 30px;
    margin-left: 70px;
    border: 0.5px solid #E5E5E5;
    border-radius: 15px;
    right: 24px;
}

.close-img-right {
    margin-top: -138px;
    margin-left: 503px;
}

.right-card-title {
    font-weight: 800;
    font-size: 30px;
    line-height: 37px;
    display: flex;
    align-items: center;
    color: #5F6163;

}

.right-card-desc {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #5F6163;
    margin-top: -13px;
}

.left-icon,
.right-logo {
    margin-left: 13px;
    margin-right: 12px;
}

.right-logo:hover,
.left-icon:hover {
    transform: scale(1.1);
    transition: 0.4s all ease;
}

.right-icons {
    display: flex;
    margin-left: auto;
}
.top{
    // border: 1px solid ;
       width: 90%;
     height: 60px;
         // margin: auto;
         margin-top: 5%;
      margin-left: 24px;
     }
     .arrow{
        margin-left: 78px;
         margin-top: 13px;
     }
     .arrow:hover{
     
        cursor: pointer;
         transform: scale(1.5);
       
     }
     .arrow1{
         margin-left: 130px;
         margin-top: 17px;
         transition: transform .2s;
     }
     .name{
         margin-top: 12px;
         font-size: 20px;
  font-weight: bolder;
         color: #186AB4;
     
     }
     
     .globalicon{
         margin-top: 10px;
       padding-left: 9px;
         padding-right: 9px;
     }
 
     .name1{
         margin-top: 8px;
         font-size: 34px;
         // font-weight: bolder;
         font-weight: 800;
         color: #186AB4;
     
     }
     .arrow1:hover{
     
         cursor: pointer;
         transform: scale(1.5);
        
     }
    

     .hover:hover{
             pointer-events: auto;
             cursor: pointer;
             // background-color: rgba(24, 25, 29, 0.1);
             /* color: white; */
             -ms-transform: scale(1.01); /* IE 9 */
               -webkit-transform: scale(1.01); /* Safari 3-8 */
               transform: scale(1.01); 
               transition: 0.2s all ease-in-out;
             //   opacity: 1 !important;
         }



@media screen and (min-width:1600px) {
    .left-icon,
    .right-logo {
        margin-left: 25px;
        margin-right: 24px;
    }
    .sidemenu{
        margin-left: 70px;
    }
    .rightmenu{
        right: 60px;
    }
}