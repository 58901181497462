.cardText{
 //   width: 90%;
    margin: auto;
    // margin-top: 48;
     padding: 48px 45px;
     h5{
        font-family: Montserrat;
        font-size: 25px;
font-weight: 800;
line-height: 37px;
letter-spacing: 0em;
text-align: left;

     }
     p{
        font-family: Montserrat;
font-size: 13px;
font-weight: lighter;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
margin-top: 29px;
color: #151312;

     }
}
.cardIcon{
    background: #F3F4F5;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display:flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img{
        Width:15.4px;
        Height:16.8px;
    }
}
.Icon-sub_box{
        
   margin-top: 50px;
    .iconBox{
        display: flex;
        justify-content: space-between;
       .cardImg{
            Width:71px;
            Height:33px;
            cursor: pointer;
        }
       
    }
}


.Card{
    width:30%;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 0.5px solid #E5E5E5;
    margin-right:33px ;
}

.check{
    display: none;
}
.icon_hover{
    display: flex;
}

.Card:hover{
    transition: .3s;
    .check{
        display: flex;
    }
    .icon_hover{
        display: none;
    }
    .cardIcon{
        width: 94px;
        border-radius: 37%;
        // border: 1PX solid black;
        
    }
    
    .cardIcon{
        img{
            // margin: 5px 10px;
             margin: 4px 4px;
            transform: scale(0.9);
        }
    }
    .cardImg{
        transform: scale(1.2);
    }
}

@media only screen and (max-width: 991.9px) {
    .Card{
        width: 100%;
        margin-bottom: 50px;
    }
}