.is-current {
  a {
    font-style: normal;
    font-weight: 700 !important;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 0;
    color: #283790 !important;
  }
}

.topFixeNav {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  background-color: white;
  height: 72px;
  position: fixed;
  z-index: 9;
  top: 0;
  padding-left: 3.4%;
  padding-right: 3.4%;
  label {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 42px;
    /* or 262% */

    display: flex;
    align-items: center;
    text-align: center;
    cursor: pointer;
    width: 161.44px;
    height: 43px;
    background: #283790;
    border-radius: 15px;
    justify-content: center;
    color: #ffffff;
    &:hover {
      transform: scale(1.1);
    }
  }
  li {
    text-decoration: none;
    margin-right: 35px;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
  ul {
    text-decoration: none;
    display: flex;
    align-items: center;
    list-style: none !important;
    padding: 0 !important;
    margin-bottom: 0 !important;
  }
  a {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 0;
    text-align: center;
    text-decoration: none;
    color: #3b4859;
    &:hover {
      transform: scale(1.1);
      color: #3b4859;
    }
  }
}
