.teamSectiom {
  display: flex;
  align-items: center;

  .Team-card {
    width: 25%;
    img {
      width: 100%;
      height: 697px;
      object-fit: cover;
    }
    .innermobile {
      width: 100%;
      display: none;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      height: 106px;
      background: linear-gradient(180deg, #283790 0%, #000000 147.45%);
    }
    h1 {
      margin-bottom: 0;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 39px;
      color: #ffffff;
    }
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 27px;
      color: #ffffff;
      margin-bottom: 0;
    }
  }
}

.bottomTeam {
  height: 186px;

  display: flex;
  align-items: center;
  background: linear-gradient(180deg, #283790 0%, #000000 147.45%);
  padding-top: 72px;
  padding-bottom: 69px;

  .inner {
    width: 25%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    &:nth-child(1) {
      padding-left: 3.4%;
      padding-right: 3.4%;
    }
  }
  h1 {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: #ffffff;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    color: #ffffff;
  }
}

@media only screen and (max-width: 991.9px) {
  .teamSectiom {
    display: block;
    align-items: center;
    .Team-card {
      width: 100%;
      .innermobile{
        display: flex;
      }
    }
  }
  .bottomTeam{
    display: none;
  }
}
