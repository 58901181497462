.token {
  height: 381px;
  background: linear-gradient(180deg, #ffffff 0%, #283790 215.49%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 3.4%;
  padding-right: 3.4%;
  h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 55px;
    line-height: 67px;
    display: flex;
    align-items: center;

    color: #283790;
  }
  h3 {
    display: none;
  }
  h5 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    display: flex;
    align-items: center;

    color: #283790;
  }
  label {
    font-style: normal;
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    line-height: 42px;
    /* or 262% */
    margin-bottom: 0px;
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background: #283790;
    border-radius: 15px;
    width: 216px;
    height: 53px;
    color: #ffffff;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
}

.tokenTwo {
  height: 381px;
  background: linear-gradient(
    180deg,
    #283790 0%,
    #3c3c3c 215.49%,
    #0c0d13 215.49%
  );
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 3.4%;
  padding-right: 3.4%;
  h3 {
    display: none;
  }
  h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 55px;
    line-height: 67px;
    display: flex;
    align-items: center;

    color: #fff;
  }
  h5 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    display: flex;
    align-items: center;

    color: #fff;
  }
  label {
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
    font-size: 16px;
    line-height: 42px;
    /* or 262% */
    border: 1.5px solid #ffffff;
    margin-bottom: 0px;
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background: transparent;
    border-radius: 15px;
    width: 216px;
    height: 53px;
    color: #ffffff;
    &:hover {
      transform: scale(1.1);
    }
  }
}

.tokenOne {
  height: 381px;
  background: linear-gradient(180deg, #000000 0%, #283790 215.49%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 3.4%;
  padding-right: 3.4%;
  h3 {
    display: none;
  }
  h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 55px;
    line-height: 67px;
    display: flex;
    align-items: center;

    color: #fff;
  }
  h5 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    display: flex;
    align-items: center;

    color: #fff;
  }
  label {
    cursor: pointer;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 42px;
    /* or 262% */
    border: 1.5px solid #ffffff;
    margin-bottom: 0px;
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background: transparent;
    border-radius: 15px;
    width: 216px;
    height: 53px;
    color: #ffffff;
    &:hover {
      transform: scale(1.1);
    }
  }
}

.tokenmodel {
  width: 100%;
  height: 680px;
  border-radius: 30px;
  padding-left: 55px;
  padding-right: 21px;

  .tokensection {
    display: flex;
    align-items: center;
    display: flex;
    align-items: start;
    width: 100%;
    height: 100%;
  }
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    display: flex;
    align-items: center;
    margin-bottom: 1.2rem;
    color: #3b4859;
    padding-top: 55px;
  }
  .right {
    width: 46%;
    // background-image: url("../../static/Image/tokenback.svg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 94%;
    border-radius: 15px;
    padding-top: 5%;
    margin-top: 2%;
  }
  .left {
    width: 54%;
    padding-right: 55px;
    label {
      background: #283790;
      cursor: pointer;
      border-radius: 15px;
      height: 72px;
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 42px;
      /* or 175% */
      justify-content: center;

      display: flex;
      align-items: center;
      text-align: center;

      color: #ffffff;
      margin-bottom: 0;
      &:hover {
        transform: scale(1.1);
      }
    }
    input {
      width: 135px;
      height: 49px;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      padding-right: 10px;
      text-align: right;

      color: #3b4859;

      opacity: 0.5;

      background: #ffffff;
      border: 0.5px solid #e5e5e5;
      border-radius: 15px;
    }
    .line {
      background-color: #e5e5e5;
      width: 100%;
      height: 0.5px;
      margin-bottom: 2rem;
    }
    .cal {
      display: flex;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2.5rem;
    }
    h5 {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      color: #3b4859;
    }

    h6 {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: right;
      margin-bottom: 0;
      color: #3b4859;
    }
  }
  .checkoutCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;

    .inner {
      width: 154px;
      height: 154px;
      display: flex;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      background: #ffffff;
      border: 0.5px solid #e5e5e5;
      border-radius: 15px;
      //   &:hover{
      //     transform: scale(1.1);
      //   }

      img{
        height: 37px;
      }
    }
    p {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      margin-top: 1rem;
      text-align: center;

      color: #3b4859;
      margin-bottom: 0;
    }
  }
}
.modal-content {
  border-radius: 30px !important;
  outline: 0;
}

.modal-lg {
  max-width: 1100px !important;
}
@media only screen and (max-width: 991.9px) {
  .tokenmodel {
    .right {
      display: none;
    }
    .left {
      width: 100%;
      padding-right: 0px;
    }
  }
  .tokenmodel {
    width: 100%;
    height: 680px;
    border-radius: 30px;
    padding-left: 5%;
    padding-right: 5%;
    .checkoutCard .inner {
      width: 103px;
      height: 100px;
      img {
        height: 33px;
      }
    }
  }
  .token {
    height: 100vh;
    background: linear-gradient(180deg, #000000 0%, #283790 215.49%);
    display: flex;
    align-items: end;
    justify-content: space-between;
    padding-left: 3.4%;
    padding-right: 3.4%;
    flex-direction: column;
    padding-top: 2.3rem;
    span {
      display: none;
    }
    img {
      width: 10rem;
      margin-bottom: 2rem;
    }
    h5 {
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 25px;
      line-height: 30px;
    }
    h1 {
      display: none;
    }
    h3 {
      font-style: normal;
      font-weight: 800;
      font-size: 55px;
      line-height: 67px;
      display: flex;
      align-items: center;

      color: #ffffff;
    }
    .left {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .tokenTwo {
    height: 100vh;
    background: linear-gradient(180deg, #000000 0%, #283790 215.49%);
    display: flex;
    align-items: end;
    justify-content: space-between;
    padding-left: 3.4%;
    padding-right: 3.4%;
    flex-direction: column;
    padding-top: 2.3rem;
    span {
      display: none;
    }
    img {
      width: 10rem;
      margin-bottom: 2rem;
    }
    h5 {
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 25px;
      line-height: 30px;
    }
    h1 {
      display: none;
    }
    h3 {
      font-style: normal;
      font-weight: 800;
      font-size: 55px;
      line-height: 67px;
      display: flex;
      align-items: center;

      color: #ffffff;
    }
    .left {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .tokenOne {
    height: 100vh;
    background: linear-gradient(180deg, #000000 0%, #283790 215.49%);
    display: flex;
    align-items: end;
    justify-content: space-between;
    padding-left: 3.4%;
    padding-right: 3.4%;
    flex-direction: column;
    padding-top: 2.3rem;
    span {
      display: none;
    }
    img {
      width: 10rem;
      margin-bottom: 2rem;
    }
    h5 {
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 25px;
      line-height: 30px;
    }
    h1 {
      display: none;
    }
    h3 {
      font-style: normal;
      font-weight: 800;
      font-size: 55px;
      line-height: 67px;
      display: flex;
      align-items: center;

      color: #ffffff;
    }
    .left {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
