.Viralcard {
  width: 48%;
  // height: 350px;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  padding: 0px;
  margin-bottom: 4%;
  // margin-right: 38px;
}
.check {
  display: none;
}
.icon_hover {
  display: flex;
}
.Vimage {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Viralcard:hover {
  cursor: pointer;
  .cardIcon {
    transition: 0.3s;
    width: 124px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 46px;
    border-radius: 42px;
    .icon_hover1 {
      margin-left: 11px;
      margin-top: 3px;
    }
    //   .check {
    //     display: flex;
    //     margin-right: 10px;
    //   }
    //   img {
    //     margin-left: 11px;
    //     margin-top: 3px;
    //   }
    //   .icon_hover {
    //     display: none;
    //   }
  }
  .check {
    display: flex;
    margin-right: 10px;
  }
  img {
  }
  .icon_hover {
    display: none;
  }
  .cardIcon {
    // border: 1PX solid black;
  }
}

.teram {
  display: none;
}
.VText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 60%;
  padding-left: 8px;
}
.VcardText {
  h5 {
    width: 316px;
    line-height: 37px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 37px;
    display: flex;
    align-items: center;
    color: #283790 !important;
    margin-top: 61px;
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: lighter;
    font-size: 13px;
    line-height: 25px;
    color: #151312;
    margin-top: 11px;
  }
  .cardIcon {
    margin-top: 50px;
    margin-bottom: 61px;
  }
}
.Vimage {
  img {
    margin-left: 67px;
    margin-top: 13px;
    height: 96.5%;
  }
}

@media only screen and (max-width: 991.9px) {
  .Viralcard {
    width: 100%;
    position: relative;
  }
  .VcardText h5 {
    font-size: 25px;
    line-height: 30px;
    position: absolute;
    top: -25px;
    padding-left: 14px;
  }
  .Vimage img {
    margin-left: 0px;
    margin-top: 13px;
    height: 96.5%;
  }
  .Vimage {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .VcardText h5 {
    font-size: 25px;
    line-height: 30px;
    position: absolute;
    top: -25px;
    padding-left: 14px;
    left: 11px;
  }
  .VcardText p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: lighter;
    font-size: 13px;
    line-height: 25px;
    color: #151312;
    margin-top: 11px;
    position: absolute;
    left: 26px;
    top: 64px;
    width: 58%;
  }
  .VcardText .cardIcon {
    margin-top: 50px;
    margin-bottom: 61px;
    position: absolute;
    bottom: 0;
    left: 24px;
  }
  .teram {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;

    color: #283790;
    padding-left: 7%;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .VText {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 49%;
    padding-left: 8px;
  }
}
