.carres1
{
    width: 100%;
    height: 39px;
    background: white;   
    z-index: 999;
    margin-bottom: 0; 
    overflow: hidden;
    position: fixed;
 
  top: 0;
 
  display: flex;
   justify-content: space-between; 
   cursor: pointer;

img{
    // width: 18px;
    // height: 18px;
    transition: 1s;
  
    &:hover {
      transform: scale(1.1);
    }
}


    }

    .BannerLogo2{
        display: flex;
        margin-left: auto;
    }
    .BannerLogo2 > div{    
        //  border: 0.5px solid #E5E5E5;
          width:70px;
         display: flex;
         align-items: center;
         justify-content: center;
      }
      .BannerLogo2 > div:nth-child(1){    
          border-left: 0.5px solid #E5E5E5;
          border-right: 0.5px solid #E5E5E5;
      
        
      }

.BannerLogo{
    display: flex;
   
}
.BannerLogo > div{    
  //  border: 0.5px solid #E5E5E5;
    width:70px;
   display: flex;
   align-items: center;
   justify-content: center;
}
.BannerLogo > div:nth-child(1){    
    border-left: 0.5px solid #E5E5E5;
    border-right: 0.5px solid #E5E5E5;

  
}


@media only screen and (max-width: 1400px) {
    .BannerLogo > div{    
        //  border: 0.5px solid #E5E5E5;
          width:40px;
        
      }
      .BannerLogo2 > div{    
        //  border: 0.5px solid #E5E5E5;
          width:40px;
        
      }
}
@media only screen and (max-width: 1310px) {
    .BannerLogo > div{    
        //  border: 0.5px solid #E5E5E5;
          width:36px;
        
      }
      .BannerLogo2 > div{    
        //  border: 0.5px solid #E5E5E5;
          width:36px;
        
      }
}
@media only screen and (max-width: 700px) {
.carres1{
    // height: 2rem;
    // background-color: #4CAF50;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    // padding-right: 5%;
    // position: fixed;
    // width: 100%;
    // z-index: 99999;
    // padding-left: 5%;
    // h5{
    //     font-size: 11px;
    // }
  
    display: none;
}
.BannerLogo > div{    
    //  border: 0.5px solid #E5E5E5;
    //   width:36px;
    display: none;
    
  }
  .BannerLogo2 > div{    
    //  border: 0.5px solid #E5E5E5;
    //   width:36px;
    display: none;
    background-color: transparent;
    
  }


}
@media only screen and (max-width: 990px){
    .carres1{
        display: none;
    }

}