// base
.flip {
  position: relative;
  overflow: hidden;

  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 37px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    color: #ffffff;
  }
  h2 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 37px;
    display: flex;
    align-items: center;

    color: #212224;
  }
  > .front,
  > .back {
    display: block;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition-duration: 0.5s;
    transition-property: transform, opacity;
  }
  > .front {
    transform: rotateY(0deg);
  }
  > .back {
    position: absolute;
    opacity: 0;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transform: rotateY(-180deg);
  }
  &:hover {
    > .front {
      transform: rotateY(180deg);
      cursor: pointer;
    }
    > .back {
      opacity: 1;
      transform: rotateY(0deg);
      cursor: pointer;
    }
  }
  &.flip-vertical {
    > .back {
      transform: rotateX(-180deg);
    }
    &:hover {
      > .front {
        transform: rotateX(180deg);
      }
      > .back {
        transform: rotateX(0deg);
      }
    }
  }
}

// custom
.flip {
  position: relative;
  display: flex;
  // margin-right: 2px;

  width: 33.33%;
  height: 385px;
  .front {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    justify-content: flex-end;
    background-size: cover !important;
    background-position: center !important;
    height: 100%;
    background-color: #4d4c4cc4;
    background-blend-mode: multiply;
    width: 100%;
    padding-bottom: 2rem;
    padding-left: 10%;
    img {
      margin-bottom: 1rem;
    }
  }
  > .back {
    display: block;
    //color: white;
    display: flex;
    flex-direction: column;
    align-items: self-start;
    justify-content: flex-end;

    background-size: cover !important;
    background-position: center !important;
    height: 100%;
    padding-bottom: 5rem;
    padding-left: 10%;
    background: #fff;
    width: 100%;
    .backImg {
      margin-bottom: 1rem;
    }
    .about-secton {
      display: flex;
      align-items: center;
      position: absolute;
      right: 5%;
      bottom: 2rem;
      h3 {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 0;
        color: #212224;
      }
      .box {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        background: rgba(40, 55, 144, 0.95);
        border-radius: 5px;
        margin-left: 10px;
        img {
        }
      }
    }
    p {
      font-size: 0.9125rem;
      line-height: 160%;
      color: #999;
    }
  }
}

.innerbannerLanding {
  height: 59px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 3.5%;
  justify-content: space-between;
  padding-right: 3.4%;
  background: linear-gradient(180deg, #283790 0%, #000000 147.45%);
  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: right;
    text-decoration-line: underline;

    color: #ffffff;
    margin-bottom: 0px;
  }
  h6 {
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 27px;
    display: flex;
    align-items: center;
    margin-bottom: 0;

    color: #ffffff;
  }
}

.flipCard {
  width: 50% !important;
  .front {
    padding-left: 6% !important;
  }
  .back {
    padding-left: 6% !important;
  }
}

.landingpage {
  height: 510px;
  background-color: #4d4c4ce0;
  background-blend-mode: multiply;
  display: flex;
  padding-left: 3.4%;
  padding-right: 3.4%;
  flex-direction: column;
  padding-top: 72px;
  justify-content: center;
  background-image: url("../../static/Image/landing.png");
  background-repeat: no-repeat;
  background-size: cover;
  h6 {
    display: none;
  }
  h5 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: #ffffff;
  }

  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 55px;
    line-height: 67px;
    color: #ffffff;
  }
  .props-object-landling {
    display: flex;
    align-items: center;
    width: 554px;
    justify-content: space-between;
  }
  label {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 42px;
    text-align: center;
    color: #ffffff;
    justify-content: center;
    border: 1.5px solid #ffffff;
    border-radius: 15px;
    align-items: center;
    width: 304px;
    height: 58px;
    margin-top: 2.5rem;
    cursor: pointer;
    display: flex;
    &:nth-child(1) {
      width: 228px;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
}

.cardSection {
  display: flex;
  background: black;
  align-items: center;
}
@media only screen and (max-width: 991.9px) {
  .innerbannerLanding {

h5{
  display: none;
}
  }
  .landingpage {
    height: 100vh;
    position: relative;
    h6 {
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 34px;
      display: flex;
      align-items: center;

      color: #ffffff;
    }
    h5 {
      display: none;
    }
    h1 {
      margin-bottom: 150px;
    }
    span {
      font-style: normal;
      font-weight: 800;
      font-size: 41px;
      line-height: 55px;
      display: flex;
      align-items: center;
      width: 100%;
      color: #ffffff;
    }
    .props-object-landling {
      display: block;
      align-items: center;
      width: 93.2%;
      position: absolute;
      bottom: 2rem;

      justify-content: space-between;
      label {
        width: 100%;
        font-weight: 700;
        font-size: 20px;
        margin-top: 1.5rem;
      }
    }
  }

  .cardSection {
    display: block;
    background: black;
    align-items: center;
  }
  .flip {
    position: relative;
    display: flex;
    width: 100%;
    .front {
      padding-left: 4%;
    }
    .back {
      padding-left: 4%;
    }
  }
  .flipCard {
    width: 100% !important;
    .front {
      padding-left: 4% !important;
    }
    .back {
      padding-left: 4% !important;
    }
  }
}
